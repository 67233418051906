import { Box } from 'atomic-layout';
import styled from 'styled-components';

const GlassBox = styled(Box)`
  box-shadow: ${({ theme }) => theme.colors.shadow};
  backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
  -webkit-backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
  border-radius: ${({ theme }) => theme.glassEffect.borderRadius};
  padding: 1rem;
  background: ${({ theme }) => theme.colors.glassBackground};
  box-sizing: border-box;
`;

const GlassBoxComponent = ({ children, ...props }) => (
  <GlassBox padding="1rem" {...props}>
    {children}
  </GlassBox>
);

export default GlassBoxComponent;
